.project-content {
  margin-top: 40px;
}

.project-content p {
  font-size: 18px;
  margin-left: 8px;
  color: #777;
  font-family: "Karla"
}

.project-content code {
  font-size: 16px;
}

.project-content a {
  color: #555;
  transition: all 0.3s ease-out 0.2s;
  text-decoration: none;
  border-bottom: 1px dotted #9c9c9c;
}

.project-content a:hover {
  color: #111;
  border-bottom: 1px dotted #111;
}