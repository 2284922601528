.main {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccccccdd;
  background-color: #fcfcfce3;
  backdrop-filter: saturate(180%) blur(6px);
  box-shadow: 0 20px 60px 6px rgba(150, 150, 150, 0.16);
}

.menu {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 81px;
  right: 0;
  z-index: 2;
  border: 1px solid #ccccccdd;
  border-top: none;
  background: #f0f0f0fa;
  box-shadow: 0 20px 60px 6px rgba(150, 150, 150, 0.4);
}

.link {
  font-family: "Karla";
  margin: 0 6px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-out 0.2s;
  color: inherit;
}

.homeLink {
  font-weight: 300;
  font-size: 20px;
  padding-left: 20px;
}