@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&family=Karla:wght@400;700&&family=Source+Code+Pro:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

html, body {
  font-family: 'Inter', sans-serif;
  background: #f3f3f3;
  overflow-y: overlay;
}

h1 {
  font-weight: 300;
  font-size: 64px;
  letter-spacing: -0.14rem;
  color: #222;
  margin-bottom: 0;
  line-height: 1.05;
}

h3 {
  font-size: 20px;
  color: #777;
  font-weight: 300;
  letter-spacing: -0.5px;
}

code, pre {
  font-family: "Source Code Pro", monospace;
  font-size: 14px !important;
}

.lnk {
  color: #555;
  transition: all 0.3s ease-out 0.2s;
  text-decoration: none;
  border-bottom: 1px dotted #9c9c9c;
}

.lnk:hover {
  color: #111;
  border-bottom: 1px dotted #111;
}