@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&family=Karla:wght@400;700&&family=Source+Code+Pro:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
html, body {
  font-family: 'Inter', sans-serif;
  background: #f3f3f3;
  overflow-y: overlay;
}

h1 {
  font-weight: 300;
  font-size: 64px;
  letter-spacing: -0.14rem;
  color: #222;
  margin-bottom: 0;
  line-height: 1.05;
}

h3 {
  font-size: 20px;
  color: #777;
  font-weight: 300;
  letter-spacing: -0.5px;
}

code, pre {
  font-family: "Source Code Pro", monospace;
  font-size: 14px !important;
}

.lnk {
  color: #555;
  transition: all 0.3s ease-out 0.2s;
  text-decoration: none;
  border-bottom: 1px dotted #9c9c9c;
}

.lnk:hover {
  color: #111;
  border-bottom: 1px dotted #111;
}
.Navbar_main__3grHS {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccccccdd;
  background-color: #fcfcfce3;
  -webkit-backdrop-filter: saturate(180%) blur(6px);
          backdrop-filter: saturate(180%) blur(6px);
  box-shadow: 0 20px 60px 6px rgba(150, 150, 150, 0.16);
}

.Navbar_menu__NKpxG {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 81px;
  right: 0;
  z-index: 2;
  border: 1px solid #ccccccdd;
  border-top: none;
  background: #f0f0f0fa;
  box-shadow: 0 20px 60px 6px rgba(150, 150, 150, 0.4);
}

.Navbar_link__TNvz9 {
  font-family: "Karla";
  margin: 0 6px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-out 0.2s;
  color: inherit;
}

.Navbar_homeLink__3COzr {
  font-weight: 300;
  font-size: 20px;
  padding-left: 20px;
}
.project-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}

.project-grid-col {
  padding-left: 30px;
  background-clip: padding-box;
}
.project-content {
  margin-top: 40px;
}

.project-content p {
  font-size: 18px;
  margin-left: 8px;
  color: #777;
  font-family: "Karla"
}

.project-content code {
  font-size: 16px;
}

.project-content a {
  color: #555;
  transition: all 0.3s ease-out 0.2s;
  text-decoration: none;
  border-bottom: 1px dotted #9c9c9c;
}

.project-content a:hover {
  color: #111;
  border-bottom: 1px dotted #111;
}
